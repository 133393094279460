import { Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { getConfig } from "src/models/Config";

interface Auth0ProviderWithConfigProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithConfig = ({
  children,
}: PropsWithChildren<Auth0ProviderWithConfigProps>): JSX.Element | null => {
  const config = getConfig();
  const domain = config.domain;
  const clientId = config.clientId;
  const redirectUri = config.redirectUri || window.location.origin;
  const audience = config.audience;

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};
