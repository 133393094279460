import React from 'react'
import { LogoHeader } from 'src/components/Layout/LogoHeader'

export const NotFound: React.FC = () => {
    return (
        <div>
            <LogoHeader />
            <div className="content-layout">
                <h1 id="page-title" className="display-3 font-weight-bold">
                    404
                </h1>
                <p>
                    <strong>Oops! We could not find that.</strong>
                </p>
                <p>
                    <br />
                    Please check the link or URL you have accessed and try
                    again.
                </p>
            </div>
        </div>
    )
}
