import React from 'react'
import { useAccount } from 'src/contexts/AccountContext'
import { TokenProps } from 'src/models/TokenProps'
import { AccountComplete } from './AccountComplete'
import { PageLoader } from '../Layout/PageLoader'
import { ConnectForm } from './ConnectForm'
import { NewAccountForm } from './NewAccountForm'
import { getConfig } from 'src/models/Config'

const { siteDomain } = getConfig()

export const AccountSetupProcess: React.FC<TokenProps> = ({
    auth0Token,
    auth0State,
}) => {
    const { state } = useAccount()
    switch (state.action) {
        default:
        case 'Check':
            return <PageLoader message="Checking your account..." />
        case 'Complete':
            return <AccountComplete />
        case 'Connect':
            return (
                <ConnectForm auth0Token={auth0Token} auth0State={auth0State} />
            )
        case 'New':
            return (
                <NewAccountForm
                    auth0Token={auth0Token}
                    auth0State={auth0State}
                />
            )
        case 'Fail':
        case 'Error':
            return (
                <div>
                    <h3>An error occurred.</h3>
                    <p>Please try again.</p>
                    <p>
                        If you were responding to an event invitation, please
                        click the button in your invitation again.
                    </p>
                    <div className="d-grid gap-2">
                        <a
                            href={siteDomain}
                            className="btn btn-outline-dark">
                            Back to Connect My Event
                        </a>
                    </div>
                </div>
            )
    }
}
