import React from 'react'
import { useAccount } from 'src/contexts/AccountContext'

interface AccountCompleteProps {
    message?: string
}
export const AccountComplete: React.FC<AccountCompleteProps> = ({
    message,
}) => {
    const { state } = useAccount()

    if (state.action === 'Complete') {
        if (state.redirect) {
            window.location.href = state.redirect;
        }
    }

    const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg'

    return (
        <div>
            <div className="loader">
                <img src={loadingImg} alt={message} />
            </div>
            <p>Your account is ready, logging you in...</p>
        </div>
    )
}
