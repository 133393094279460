import React from 'react'

interface PageLoaderProps {
    message?: string
}
export const PageLoader: React.FC<PageLoaderProps> = ({ message }) => {
    const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg'

    return (
        <div>
            <div className="loader">
                <img src={loadingImg} alt={message} />
            </div>
            <p>{message}</p>
        </div>
    )
}
