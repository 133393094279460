import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AccountSetupProcess } from 'src/components/AccountSetup/AccountSetupProcess'
import { LogoHeader } from 'src/components/Layout/LogoHeader'
import { useAccount } from 'src/contexts/AccountContext'
import { checkAccountBySigninToken } from 'src/api/AccountService'

export const AccountSetup: React.FC = () => {
    const [searchParams] = useSearchParams()

    const { setState } = useAccount()

    const auth0Token = searchParams.get('token')
    const auth0State = searchParams.get('state')

    useEffect(() => {
        let isMounted = true

        const checkAccount = async () => {
            const { data, error } = await checkAccountBySigninToken(
                auth0Token,
                auth0State
            )

            if (!isMounted) {
                return
            }

            if (data) {                
                setState({
                    action: data.action,
                    redirect: data.redirect,
                    account: data.account
                })
            }

            if (error) {
                console.log(error)
                setState({
                    action: 'Error',
                    reason: error.message,
                })
            }
        }

        checkAccount()

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <div>
            <LogoHeader />
            <AccountSetupProcess auth0Token={auth0Token} auth0State={auth0State} />
        </div>
    )
}
