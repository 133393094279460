import { AxiosRequestConfig } from 'axios'
import { AccountAppState } from 'src/models/Account'
import { getConfig } from 'src/models/Config'
import { ApiResponse } from '../models/ApiResponse'
import { callExternalApi } from '../services/ExternalServiceApi'

const { apiServer } = getConfig();

/**
 * Call the CME Api, passing the Auth0 pre-login token and state, this will return the current account state.
 * @param token 
 * @param state 
 * @returns 
 */
export const checkAccountBySigninToken = async (
    token: string | null,
    state: string | null
): Promise<ApiResponse<AccountAppState>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServer}/account/check`,
        method: 'POST',
        params: {
            token: token,
            state: state,
        },
        headers: {
            'content-type': 'application/json',
        },
    }

    const { data, error } = (await callExternalApi<AccountAppState>({ config })) as ApiResponse<AccountAppState>

    return {
        data,
        error,
    }
}

export const connectAccount = async (
    token: string | null,
    state: string | null,
    firstName: string | null,
    lastName: string | null,
    avatar: string | null
): Promise<ApiResponse<AccountAppState>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServer}/account/connect`,
        method: 'POST',
        params: {
            token: token,
            state: state,
            firstName: firstName,
            lastName: lastName,
            avatar: avatar
        },
        headers: {
            'content-type': 'application/json',
        },
    }

    const { data, error } = (await callExternalApi<AccountAppState>({ config })) as ApiResponse<AccountAppState>

    return {
        data,
        error,
    }
}

export const createAccount = async (
    token: string | null,
    state: string | null,
    firstName: string | null,
    lastName: string | null,
    avatar: string | null
): Promise<ApiResponse<AccountAppState>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServer}/account/new-account`,
        method: 'POST',
        params: {
            token: token,
            state: state,
            firstName: firstName,
            lastName: lastName,
            avatar: avatar
        },
        headers: {
            'content-type': 'application/json',
        },
    }

    const { data, error } = (await callExternalApi<AccountAppState>({ config })) as ApiResponse<AccountAppState>

    return {
        data,
        error,
    }
}
