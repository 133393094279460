export const BacktroundImages: string[] = [
    'dm-01.jpg',
    'dm-02.jpg',
    'dm-03.jpg',
    'dm-front.jpg',
    'feedback-01.jpg',
    'feedback-02.jpg',
    'feedback-front.jpg',
    'fully-customisable-platform-01.jpg',
    'fully-customisable-platform-02.jpg',
    'fully-customisable-platform-03.jpg',
    'fully-customisable-platform-front.jpg',
    'polling-01.jpg',
    'polling-02.jpg',
    'polling-03.jpg',
    'questionnaires-01.jpg',
    'questionnaires-02.jpg',
    'questionnaires-03.jpg',
    'questionnaires-front.jpg',
    'secure-guest-invitations-01.jpg',
    'secure-guest-invitations-02.jpg',
    'secure-guest-invitations-03.jpg',
    'secure-guest-invitations-front.jpg',
]

export function getBackgroundImage() {
    const backgroundImage: string =
        BacktroundImages[Math.floor(Math.random() * BacktroundImages.length)]
    return `https://cdn.connectmyevent.com/cdn/master/stock/${backgroundImage}`
}