import { useAuth0 } from '@auth0/auth0-react';
import React from 'react'
import { Avatar } from 'src/components/AccountSetup/Avatar';
import { LogoHeader } from '../components/Layout/LogoHeader'

export const Profile: React.FC = () => {
    const { user } = useAuth0();
    return (
        <div>
            <LogoHeader />
            <div className="d-flex align-items-center justify-content-center my-4 mx-4">
                <Avatar avatarUrl={user?.picture} />
            </div>
            <h2>Manage your account</h2>
        </div>
    )
}
