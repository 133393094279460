import React from 'react'
import { getConfig } from 'src/models/Config'

const { siteDomain } = getConfig()

export const FooterLinks: React.FC = () => {
    return (
        <footer>
            <div className="powered-by">
                <div>Powered by</div>
                <div>
                    <a href={siteDomain} target="_blank" rel="noreferrer" className="text-decoration-none">
                        <img
                            src="https://cdn.connectmyevent.com/cdn/master/logo-full-white.svg"
                            alt="Connect My Event"
                        />
                    </a>
                </div>
            </div>
            <div>
                <span>
                    © 2022{' '}
                    <a href={siteDomain} target="_blank" rel="noreferrer" className="text-decoration-none">
                        Connect My Event
                    </a>
                </span>
                <span> • </span>
                <a
                    href={`${siteDomain}/legal/terms-of-use`}
                    target="_blank"
                    rel="noreferrer">
                    Legal and Policy Center
                </a>
                <span> • </span>
                <a
                    className="text-white"
                    href="https://support.connectmyevent.com/"
                    target="_blank"
                    rel="noreferrer">
                    Help and Support
                </a>
            </div>
        </footer>
    )
}
