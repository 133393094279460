import * as React from 'react'
import { AccountAppState } from 'src/models/Account'

type Dispatch = (state: AccountAppState) => void
type AccountProviderProps = { children: React.ReactNode | React.ReactNode[] }

const AccountStateContext = React.createContext<
    { state: AccountAppState; setState: Dispatch } | undefined
>(undefined)

function accountReducer(
    state: AccountAppState,
    action: AccountAppState
): AccountAppState {
    switch (action.action) {
        case 'Complete':
            return {
                ...state,
                action: 'Complete',
                redirect: action.redirect,
                account: action.account
            }
        case 'Error':
            return {
                ...state,
                action: 'Error',
                reason: action.reason,
            }
    }
    return {
        ...state,
        action: action.action,
        reason: action.reason,
        redirect: action.redirect,
        account: action.account,
    }
}

function AccountProvider({ children }: AccountProviderProps) {
    const [state, setState] = React.useReducer(accountReducer, {
        action: 'Check',
    })
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, setState }
    return (
        <AccountStateContext.Provider value={value}>
            {children}
        </AccountStateContext.Provider>
    )
}

function useAccount() {
    const context = React.useContext(AccountStateContext)
    if (context === undefined) {
        throw new Error('useAccount must be used within a AccountProvider')
    }
    return context
}

export { AccountProvider, useAccount }
