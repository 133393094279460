import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PageLayout } from './components/Layout/PageLayout'
import { PageLoader } from './components/Layout/PageLoader'
import { getBackgroundImage } from './models/BackgroundImages'
import { AccountSetup } from './pages/AccountSetup'
import { Home } from './pages/Home'
import { NotFound } from './pages/NotFound'
import { Profile } from './pages/Profile'
import { ProtectedRoute } from './routing/ProtectedRoute'

export const App: React.FC = () => {
    const { isLoading } = useAuth0()

    if (isLoading) {
        return <PageLoader />
    }
    return (
        <BrowserRouter>
            <PageLayout backgroundImage={getBackgroundImage()}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/account"
                        element={<ProtectedRoute component={Profile} />}
                    />
                    <Route path="/setup-account" element={<AccountSetup />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </PageLayout>
        </BrowserRouter>
    )
}
