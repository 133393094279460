import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import { Auth0ProviderWithConfig } from './services/Auth0ProviderWithConfig'
import { AccountProvider } from './contexts/AccountContext'
import './sass/app.scss'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
    <AccountProvider>
        <React.StrictMode>
            <Auth0ProviderWithConfig>
                <App />
            </Auth0ProviderWithConfig>
        </React.StrictMode>
    </AccountProvider>
)
