import React from 'react'
import { AccountInfo } from 'src/models/Account'

interface NameFieldsProps {
    account: AccountInfo | undefined
    loading: boolean
    error: string
}

export const NameFields: React.FC<NameFieldsProps> = ({
    account,
    loading,
    error,
}) => {
    return (
        <div>
            <p>
                <strong>Please confirm your name to continue.</strong>
            </p>
            <div className="name-fields">
                <div className="col">
                    <label htmlFor="firstName" className="d-block">
                        First name:
                    </label>
                    <input
                        className="form-control"
                        defaultValue={account?.firstName}
                        id="firstName"
                    />
                </div>
                <div className="col">
                    <label htmlFor="firstName" className="d-block">
                        Last name:
                    </label>
                    <input
                        className="form-control"
                        defaultValue={account?.lastName}
                        id="lastName"
                    />
                </div>
            </div>
            {error && !loading ? (
                <p style={{ color: 'red', marginTop: '-.5rem' }}>{error}</p>
            ) : (
                ''
            )}
            <div className="d-grid">
                {loading ? (
                    <div>Please wait...</div>
                ) : (
                    <button className="btn btn-success" type="submit">
                        Connect Account
                    </button>
                )}
            </div>
        </div>
    )
}
