import React, { useState } from 'react'
import { useAccount } from 'src/contexts/AccountContext'
import { TokenProps } from 'src/models/TokenProps'
import { connectAccount } from 'src/api/AccountService'
import { Avatar } from './Avatar'
import { NameFields } from './NameFields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/pro-duotone-svg-icons'

export const ConnectForm: React.FC<TokenProps> = ({
    auth0Token,
    auth0State,
}) => {
    const { state, setState } = useAccount()
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e: any) => {
        setLoading(true)
        e.preventDefault()
        const { firstName, lastName } = e.target.elements

        if (!firstName.value) {
            setErrorMessage('Please enter your first name.')
        }

        if (!lastName.value) {
            setErrorMessage('Please enter your last name.')
        }

        if (!firstName.value && !lastName.value) {
            setErrorMessage('Please enter your first and last name.')
        }

        if (!firstName.value || !lastName.value) {
            setLoading(false)
            return
        }

        const { data, error } = await connectAccount(
            auth0Token,
            auth0State,
            firstName.value,
            lastName.value,
            ''
        )

        if (data?.action == 'Fail') {
            const actionErrorMessage = data.reason ?? "An error occurred. Please try again."
            setErrorMessage(actionErrorMessage)
            setLoading(false)
            return
        }

        if (error) {
            setState({
                action: 'Error',
                reason: error.message,
            })
        }


        if (data) {
            setState(data)
            setLoading(false)
        }
    }
    return (
        <div>
            <h2>Connect your account</h2>
            <div className="avatar-section">
                <Avatar avatarUrl={state?.account?.remotePicture} />
                <div className="flex-fill">
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        size="2x"
                        style={{ paddingTop: '1.5rem', color: 'lightgrey' }}
                    />
                </div>
                <Avatar avatarUrl={state?.account?.localPicture} />
            </div>
            <p>
                Looks like an account already exists with this email address,
                would you like to connect to it?
            </p>
            <form onSubmit={handleSubmit}>
                <NameFields
                    account={state?.account}
                    loading={loading}
                    error={errorMessage}
                />
            </form>
        </div>
    )
}
