import React from 'react'
import { getConfig } from 'src/models/Config'

const { siteDomain } = getConfig()

export const LogoHeader: React.FC = () => {
    const logo =
        'https://cdn.connectmyevent.com/cdn/master/logo-full-dark.png'
    return (
        <a className="logo" id="login-logo" href={siteDomain}>
            <img src={logo} alt="Connect My Event" />
        </a>
    )
}
