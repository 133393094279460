import React from 'react'

interface AvatarProps {
    size?: 'small' | 'medium' | 'large',
    avatarUrl: string | undefined
}

export const Avatar: React.FC<AvatarProps> = ({ avatarUrl, size = 'medium' }) => {
    return (
        <div>
            <img
                src={avatarUrl}
                alt="Your profile picture"
                className={`avatar avatar-${size}`}
            />
        </div>
    )
}
