import React from 'react'
import { LogoHeader } from '../components/Layout/LogoHeader'

export const Home: React.FC = () => {
    return (
        <div>
            <LogoHeader />
            <div>
                <h3>Oops! You shouldn&apos;t be here!</h3>
                <p>
                    This service cannot be accessed directly. Please check the
                    link you have used to access this page, and return to the
                    website.
                </p>
                <p>
                    <a className="btn btn-outline-teal" href="/account">
                        Return to Connect My Event
                    </a>
                </p>
            </div>
        </div>
    )
}
