import React from 'react'
import { FooterLinks } from './FooterLinks'

type PageLayoutProps = {
    backgroundImage?: string
    children: React.ReactNode | React.ReactNode[]
}

export const PageLayout = ({ children, backgroundImage }: PageLayoutProps) => {
    if (!backgroundImage) {
        document.body.classList.add('no-image')
    }
    return (
        <div className="login-wrapper min-vh-100">
            <div className="login-content">
                <div className="login-panel-wrap">
                    <div className="login-panel">
                        <div className="login-content">{children}</div>
                    </div>
                    {backgroundImage && (
                        <div className="login-image">
                            <div
                                className="bg-panel"
                                style={{
                                    backgroundImage: `url(${backgroundImage})`,
                                }}
                            />
                            <FooterLinks />
                        </div>
                    )}
                </div>
                <div className="login-footer-mobile">
                    <FooterLinks />
                </div>
            </div>
        </div>
    )
}
